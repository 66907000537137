import { Document, Page } from 'react-pdf';


const Resume = () => {

  return (
    <div>
      <Document file="/public/img/Dwane_Richards_2022_Resume.pdf">
      </Document>

    </div>
   );
}

export default Resume;
